<template>
  <div class="_bg-default _80w _100vh pb-3 px-3">
    <div v-if="notify" class="_100top">
      <section class="mb-5 mt-5">
        <h3 class="mb-n3">Notification</h3>
        <v-divider inset></v-divider>
      </section>
      <div v-for="log in notify" :key="log.id">
        <v-card
          class="my-3 pa-3"
          :elevation="log.read_at ? 0 : 1"
          @click="viewNotification(log)"
          :color="log.read_at ? 'white' : 'blue lighten-5'"
        >
          <div class="d-flex">
            <div class="mr-3">
              <v-btn fab depressed color="purple" dark>
                <v-icon v-if="log.tipeModel == 'Feed'"
                  >mdi-comment-quote</v-icon
                >
                <v-icon v-if="log.tipeModel == 'Forum'"
                  >mdi-comment-quote</v-icon
                >
                <v-icon
                  v-if="
                    log.tipeModel == 'Konsultasi' ||
                      log.tipeModel == 'konsultasi_waktu' ||
                      log.tipeModel == 'konsultasi_booking'
                  "
                  >mdi-chat</v-icon
                >
                <v-icon v-if="log.tipeModel == 'Artikel'">mdi-book</v-icon>
                <v-icon v-if="log.tipeModel == 'Ebook'">mdi-book</v-icon>
                <v-icon v-if="log.tipeModel == 'Connection'"
                  >mdi-account-switch</v-icon
                >
              </v-btn>
            </div>
            <div>
              <section>
                <b>{{ log.keterangan }}</b>
              </section>
              <section>
                <small
                  ><b :class="log.read_at ? 'gray--text' : 'blue--text'">
                    {{ $date(log.created_at).fromNow() }}
                  </b></small
                >
              </section>
            </div>
          </div>
        </v-card>
      </div>
      <div v-if="notify" v-observe-visibility="handleScroll"></div>

      <div v-if="!notify.length" class="d-flex justify-center">
        <section class="pa-2">
          <h3 class="text-center">No Notification</h3>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "notification",

  data() {
    return {
      currentPage: 1,
      limit: 10,
    };
  },
  computed: {
    ...mapState({
      notify: (state) => state.notification.notification,
      last: (state) => state.notification.last_page,
    }),
  },
  mounted() {
    this.fetchItem();
  },
  methods: {
    fetchItem() {
      this.$store.dispatch("notification/listNotification", {
        page: this.currentPage,
        per_page: this.limit,
      });
    },
    viewNotification(item) {
      this.$store
        .dispatch("notification/viewNotification", item.idNotif)
        .then((data) => {
          if (data.status == "success") {
            this.$store.dispatch("notification/counter", {
              page: 1,
              per_page: 100,
            });
            switch (item.tipeModel) {
              case "Feed":
                this.$router.push(`/psikolog/feed/${item.idModel}`);
                break;
              case "Forum":
                this.$router.push(`/psikolog/thread/${item.idModel}`);
                break;
              case "Artikel":
                this.$router.push(`/article/${item.idModel}/view`);
                break;
              case "Ebook":
                this.$router.push(`/ebook/${item.idModel}/private`);
                break;
              case "Connection":
                this.$router.push(`/psikolog/koneksi`);
                break;
              case "Konsultasi":
                this.$router.push(`/psikolog/counseling`);
                break;
              case "konsultasi_waktu":
                this.$router.push(`/psikolog/counseling`);
                break;
              case "konsultasi_booking":
                this.$router.push(`/psikolog/counseling`);
                break;
            }
          } else {
            this.$router.push(`/page-not-found`);
          }
        });
    },
    handleScroll(isVisible) {
      if (isVisible) {
        if (this.currentPage <= this.last) {
          this.currentPage++;
          this.fetchItem();
        }
      }
    },
  },
};
</script>

<style scoped>
.__overflow__hidden {
  overflow: hidden !important;
  box-sizing: border-box;
}
.__template__cover {
  width: 100%;
  height: 230px;
  background: #f3e5f5;
}
.__relative {
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
}
.cover__ {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
